<template>
    <el-tabs v-model="categoryTab" type="card" class="storeDecorate-container tabs-full tab-red">
        <el-tab-pane label="店内分类管理" name="categoryManage">
            <div class="operate-btn">
                <div class="left">
                    <el-button size="medium" class="btn-red-line" @click="delAllBtn">全部删除</el-button>
                    <el-button size="medium" class="btn-red-line" @click="openAll">全部展开</el-button>
                    <el-button size="medium" class="btn-red-line" @click="closeAll">全部收起</el-button>
                </div>
                <div class="right">
                    <el-button size="medium" class="btn-red" @click="addBtn(null)">添加新分类</el-button>
                </div>
            </div>

            <div class="category-table" style="margin-top: 20px">
                <div class="line head">
                    <div class="td">分类</div>
                    <div class="td text-center">移动</div>
                    <div class="td text-center">添加子分类</div>
                    <div class="td text-center">操作</div>
                </div>
                <div class="no-data" v-if="categoryList.length === 0">暂无数据</div>
                <div class="one-level" v-for="(oneItem, oneIndex) in categoryList" :key="`categoryList_${oneIndex}`">
                    <div class="line body">
                        <div class="td flex align-center">
                            <i class="iconfont down" :class="{current: oneItem.open}" @click="oneItem.open = !oneItem.open">&#xe602;</i>
                            <el-input v-model.trim="oneItem.store_class_sort" size="mini" style="width: 28px;" class="category-sort"></el-input>
                            <el-input v-model.trim="oneItem.store_class_name" size="small" style="width: 180px; margin-left: 10px"></el-input>
                        </div>
                        <div class="td text-center">
                            <i class="iconfont move-btn" @click="toTop(oneItem, 'level1', oneIndex)" v-if="oneIndex !== 0">&#xe684;</i>
                            <i class="iconfont place24" v-else></i>
                            <i class="iconfont move-btn" @click="toPrev(oneItem, 'level1', oneIndex)" v-if="oneIndex !== 0">&#xe685;</i>
                            <i class="iconfont place24" v-else></i>
                            <i class="iconfont move-btn" @click="toEnd(oneItem, 'level1', oneIndex)" v-if="oneIndex !== categoryList.length - 1">&#xe686;</i>
                            <i class="iconfont place24" v-else></i>
                            <i class="iconfont move-btn" @click="toNext(oneItem, 'level1', oneIndex)" v-if="oneIndex !== categoryList.length - 1">&#xe687;</i>
                            <i class="iconfont place24" v-else></i>
                        </div>
                        <div class="td text-center">
                            <el-link :underline="false" class="link-blue-light" @click="addBtn(oneItem)">添加子分类</el-link>
                        </div>
                        <div class="td text-center">
                            <el-link :underline="false" type="danger" @click="delBtn(oneItem, oneIndex)">删除</el-link>
                        </div>
                    </div>
                    <template v-if="oneItem.children.length > 0">
                        <div class="two-level" v-show="oneItem.open">
                            <div class="line body" v-for="(twoItem, twoIndex) in oneItem.children" :key="`children_${twoIndex}`">
                                <div class="td flex align-center">
                                    <i class="iconfont placeIcon"></i>
                                    <i class="iconfont down">&#xe665;</i>
                                    <el-input v-model.trim="twoItem.store_class_sort" size="mini" style="width: 28px;" class="category-sort"></el-input>
                                    <el-input v-model.trim="twoItem.store_class_name" size="small" style="width: 180px; margin-left: 10px"></el-input>
                                </div>
                                <div class="td text-center">
                                    <i class="iconfont move-btn" @click="toTop(twoItem, 'level2', twoIndex, oneItem)" v-if="twoIndex !== 0">&#xe684;</i>
                                    <i class="iconfont place24" v-else></i>
                                    <i class="iconfont move-btn" @click="toPrev(twoItem, 'level2', twoIndex, oneItem)" v-if="twoIndex !== 0">&#xe685;</i>
                                    <i class="iconfont place24" v-else></i>
                                    <i class="iconfont move-btn" @click="toEnd(twoItem, 'level2', twoIndex, oneItem)" v-if="twoIndex !== oneItem.children.length - 1">&#xe686;</i>
                                    <i class="iconfont place24" v-else></i>
                                    <i class="iconfont move-btn" @click="toNext(twoItem, 'level2', twoIndex, oneItem)" v-if="twoIndex !== oneItem.children.length - 1">&#xe687;</i>
                                    <i class="iconfont place24" v-else></i>
                                </div>
                                <div class="td text-center"></div>
                                <div class="td text-center">
                                    <el-link :underline="false" type="danger" @click="delBtn(twoItem, twoIndex, oneItem)">删除</el-link>
                                </div>
                            </div>
                        </div>
                    </template>
                </div>
            </div>
            <div class="storeSign-btn">
                <el-button class="btn-red" @click="submitBtn">提交</el-button>
                <router-link tag="a" target="_blank" :to="{path: '/student/store/index'}"><el-button class="btn-red btn-margin">预览</el-button></router-link>
            </div>
        </el-tab-pane>
    </el-tabs>
</template>

<script>
    import { mapActions } from 'vuex';
    export default {
        data() {
            return {
                categoryTab: 'categoryManage',
                categoryList: [],
            }
        },
        mounted() {
            this.getCategoryList()
        },
        methods: {
            ...mapActions([
                'setDecorationMenusList'
            ]),
            updateMenus() {
                if (this.$common.isCompetion()) {
                    this.setDecorationMenusList();
                }
            },
            // 获取分类列表
            getCategoryList() {
                this.$http.axiosGet(this.$api.decorateClassList, (res) => {
                    if (res.code === 200) {
                        this.updateMenus();
                        this.categoryList = res.data.list.map(item => {
                            item.open = true;
                            return item;
                        })
                    } else {
                        this.$message.warning(res.msg)
                    }
                }, (err) => {
                    console.log(err)
                })
            },
            // 添加
            addBtn(row = null) {
                if (row) {
                    let addItem = {
                        store_class_name: '',
                    }
                    if (row.children.length === 0) {
                        addItem.store_class_sort = row.children.length + 1
                    } else {
                        addItem.store_class_sort = Number(row.children[row.children.length - 1].store_class_sort) + 1
                    }
                    row.children.push(addItem)
                } else {
                    let addItem = {
                        store_class_name: '',
                        open: true,
                        children: []
                    }
                    if (this.categoryList.length === 0) {
                        addItem.store_class_sort = this.categoryList.length + 1
                    } else {
                        addItem.store_class_sort = Number(this.categoryList[this.categoryList.length - 1].store_class_sort) + 1
                    }
                    this.categoryList.push(addItem)
                }
            },
            // 提交
            submitBtn() {
                if (this.categoryList.length === 0) {
                    this.$message.warning('请先添加分类')
                    return false
                } else {
                    for (let i = 0; i < this.categoryList.length; i++) {
                        if (this.categoryList[i].store_class_name === '') {
                            this.$message.warning('请输入分类名称')
                            return
                        }
                        if (this.categoryList[i].children.length > 0) {
                            for (let k = 0; k < this.categoryList[i].children.length; k++) {
                                if (this.categoryList[i].children[k].store_class_name === '') {
                                    this.$message.warning('请输入子分类名称')
                                    return
                                }
                            }
                        }
                    }
                }

                this.$http.axiosPost(this.$api.decorateSaveClass, {store_class_arr: this.categoryList}, (res) => {
                    if (res.code === 200) {
                        this.$message.success(res.msg)
                        this.getCategoryList()
                    } else {
                        this.$message.warning(res.msg)
                    }
                }, (err) => {
                    console.log(err)
                })
            },
            // 删除
            delBtn(row, index, oneRow) {
                if (row.id) {
                    this.$confirm('是否删除该分类，删除完将无法恢复', '提示消息', {
                        confirmButtonText: '确定',
                        cancelButtonText: '取消',
                        confirmButtonClass: 'btn-red',
                        type: 'warning',
                    }).then(() => {
                        this.$http.axiosGetBy(this.$api.decorateDelClass, {id: row.id}, (res) => {
                            if (res.code === 200) {
                                this.$message.success(res.msg)
                                this.getCategoryList();
                            } else {
                                this.$message.warning(res.msg)
                            }
                        }, (err) => {
                            console.log(err)
                        })
                    }).catch(() => {
                        this.$message.info('已取消删除')
                    });
                } else {
                    if (row.hasOwnProperty('open')) {
                        this.categoryList.splice(index, 1)
                    } else {
                        oneRow.children.splice(index, 1)
                    }
                }
            },
            // 置顶
            toTop(row, level, index, oneRow) {
                let cloneRow = JSON.parse(JSON.stringify(row))
                if (level === 'level1') {
                    this.categoryList.splice(index, 1)
                    this.categoryList.unshift(cloneRow)
                    this.sortNumber(this.categoryList)
                } else if (level === 'level2') {
                    oneRow.children.splice(index, 1)
                    oneRow.children.unshift(cloneRow)
                    this.sortNumber(oneRow.children)
                }
            },
            // 上移
            toPrev(row, level, index, oneRow) {
                let cloneRow = JSON.parse(JSON.stringify(row))
                if (level === 'level1') {
                    this.categoryList.splice(index - 1, 0, cloneRow)
                    this.categoryList.splice(index + 1, 1)
                    this.sortNumber(this.categoryList)
                } else if (level === 'level2') {
                    oneRow.children.splice(index - 1, 0, cloneRow)
                    oneRow.children.splice(index + 1, 1)
                    this.sortNumber(oneRow.children)
                }
            },
            // 置底
            toEnd(row, level, index, oneRow) {
                let cloneRow = JSON.parse(JSON.stringify(row))
                if (level === 'level1') {
                    this.categoryList.splice(index, 1)
                    this.categoryList.push(cloneRow)
                    this.sortNumber(this.categoryList)
                } else if (level === 'level2') {
                    oneRow.children.splice(index, 1)
                    oneRow.children.push(cloneRow)
                    this.sortNumber(oneRow.children)
                }
            },
            // 下移
            toNext(row, level, index, oneRow) {
                let cloneRow = JSON.parse(JSON.stringify(row))
                if (level === 'level1') {
                    this.categoryList.splice(index + 2, 0, cloneRow)
                    this.categoryList.splice(index, 1)
                    this.sortNumber(this.categoryList)
                } else if (level === 'level2') {
                    oneRow.children.splice(index + 2, 0, cloneRow)
                    oneRow.children.splice(index, 1)
                    this.sortNumber(oneRow.children)
                }
            },
            // 排序
            sortNumber(arr) {
                for (let i = 0; i < arr.length; i++) {
                    arr[i].store_class_sort = i + 1
                }
            },
            // 全部删除
            delAllBtn() {
                if (this.categoryList.length === 0) {
                    this.$message.warning('暂无分类，请先添加分类')
                    return false
                }
                this.$confirm('是否删除全部分类，删除完将无法恢复', '提示消息', {
                    confirmButtonText: '确定',
                    cancelButtonText: '取消',
                    confirmButtonClass: 'btn-red',
                    type: 'warning',
                }).then(() => {
                    this.$http.axiosGet(this.$api.decorateDelAllClass, (res) => {
                        if (res.code === 200) {
                            this.$message.success(res.msg)
                            // this.categoryList = []
                            this.getCategoryList()
                        } else {
                            this.$message.warning(res.msg)
                        }
                    }, (err) => {
                        console.log(err)
                    })
                }).catch(() => {
                    this.$message.info('已取消删除')
                });
            },
            // 全部展开
            openAll() {
                this.categoryList = this.categoryList.map(item => {
                    item.open = true
                    return item
                })
            },
            // 全部收起
            closeAll() {
                this.categoryList = this.categoryList.map(item => {
                    item.open = false
                    return item
                })
            },
            toPreview() {
                this.$router.push('/student/store/index')
            }
        }
    }
</script>

<style scoped lang="scss">
    .text-center {
        text-align: center;
    }
    .storeDecorate-container {
        min-height: calc(100vh - 120px);
        box-sizing: border-box;
        display: flex;
        flex-direction: column;
        .el-tab-pane {
            padding: 20px 20px 50px;
            min-height: calc(100vh - 166px); //
            box-sizing: border-box; //
            display: flex;
            flex-direction: column;
        }
        .operate-btn {
            display: flex;
            justify-content: space-between;
        }
    }
    .category-table {
        width: 100%;
        /*border: 1px solid #eee;*/
        height: 1%; //
        flex: 1; //
        .line {
            display: flex;
            align-items: center;
            /*border-top: 1px solid #eee;*/
            border-left: 1px solid #eee; //
            border-right: 1px solid #eee; //
            border-bottom: 1px solid #eee; //
            &.head {
                height: 46px;
                background: #E5E5E5;
            }
            &.body {
                height: 60px;
            }
            .td {
                width: 25%;
                padding: 0 10px;
                .down {
                    cursor: pointer;
                    color: #7D7D7D;
                    width: 20px;
                    height: 20px;
                    line-height: 20px;
                    text-align: center;
                    margin-right: 4px;
                    &.current {
                        transform: rotate(90deg);
                    }
                }
                .placeIcon {
                    width: 20px;
                    height: 20px;
                    margin-right: 4px;
                }
                .place24 {
                    display: inline-block;
                    width: 24px;
                    height: 24px;
                }
            }
            &:hover {
                background: #E5E5E5;
            }
        }
        .move-btn {
            width: 24px;
            height: 24px;
            text-align: center;
            line-height: 24px;
            display: inline-block;
            font-size: 16px;
            color: #7D7D7D;
            cursor: pointer;
            transition: all .3s;
            &:hover {
                color: #FD4446;
            }
        }
        .category-sort {
            /deep/ .el-input__inner {
                text-align: center;
                padding: 0;
            }
        }
    }
    .no-data {
        text-align: center;
        line-height: 60px;
        color: #999;
        border-left: 1px solid #eee;
        border-right: 1px solid #eee;
        border-bottom: 1px solid #eee;
    }
    .storeSign-btn {
        margin-top: 20px;
        text-align: center;
    }
</style>